/* ========================================================================
* DOM-based Routing
* Based on http://goo.gl/EUTi53 by Paul Irish
*
* Only fires on body classes that match. If a body class contains a dash,
* replace the dash with an underscore when adding it to the object below.
*
* .noConflict()
* The routing is enclosed within an anonymous function so that you can
* always reference jQuery with $, even when in .noConflict() mode.
* ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages
        var viewportWidth = $(window).width();

        // Fastclick
        FastClick.attach(document.body);

        // Remove empty p tags
        $('p:empty').remove();

        // External links
        $('a').filter(function(){
          return this.hostname && this.hostname !== location.hostname;
        }).addClass('external').attr('target', '_blank');

        /* Custom Header Selects */
        $(".locations-select").click(function (e) {
          console.log("test");
          e.target.classList.toggle("show-dropdown");
        });

        //*Mobile Menu*//
        $( ".c-header__toggle" ).click(function(e) {
          e.preventDefault();

          $('.c-mobile__menu').animate({height: 'toggle'});
        });


        //search menu
    //    $( ".search-b" ).click(function(e) {
        //  e.preventDefault();
        //  $(".m-search").toggleClass("reveal");

      //  });



      $(function() {
        $('a[href*=#]:not([href=#])').click(function() {
          if (location.pathname.replace(/^\//,'') === this.pathname.replace(/^\//,'') && location.hostname === this.hostname) {
            var target = $(this.hash);
            target = target.length ? target : $('[name=' + this.hash.slice(1) +']');
            if (target.length) {
              $('html,body').animate({
                scrollTop: target.offset().top
              }, 1000);
              return false;
            }
          }
        });
      });


        // for the mobile animation

        $(".c-header__toggle").click(function(){
          $(this).toggleClass("is-active");
        });

        // newsletter signup

        $(".ctct-form-custom").click(function(){
          $(this).toggleClass("is-active");
        });




        // Class on scroll LOGO
        var elWrap = $('.c-hero'),
        functionDelay   = 10000;
        if (viewportWidth >= 1023) {
          $(window).scroll(function() {
            var scrollX = $(this).scrollTop();

            if (scrollX >= 60) {
              $(elWrap).delay(functionDelay).addClass('scrolling');
            } else {
              $(elWrap).removeClass('scrolling');
            }
          });
        }


        // Class on scroll HTML
        var headscroll = $('html');
        if (viewportWidth >= 200) {
          $(window).scroll(function() {
            var scrollX = $(this).scrollTop();

            if (scrollX >= 10) {
              $(headscroll).addClass('scrolled');
            } else {
              $(headscroll).removeClass('scrolled');
            }
          });
        }


        // Class on scroll Red Arrow
        var revealscroll = $('.scroll-top');
        if (viewportWidth >= 200) {
          $(window).scroll(function() {
            var scrollX = $(this).scrollTop();

            if (scrollX >= 200) {
              $(revealscroll).addClass('reveal');
            } else {
              $(revealscroll).removeClass('reveal');
            }
          });
        }




        // Page loader
        // IE10+
        document.getElementsByTagName( "html" )[0].classList.remove( "loading" );

        // All browsers
        document.getElementsByTagName( "html" )[0].className.replace( /loading/, "" );

        // Or with jQuery
        $( "html" ).removeClass( "loading" );



        // Mobile Drop Menu Primary Nav
        $(function () {
          var children = $('.m-nav > li > a').filter(function () { return $(this).nextAll().length > 0 ;});
          $('<span class="drop-menu"><i class="fas fa-chevron-down"></i></span>').insertAfter(children);
          $('.m-nav .drop-menu').click(function (e) {
            $(this).next().slideToggle(300);
            return false;
          });
        });

      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired

        if( /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) ) {
          // tasks to do if it is a Mobile Device
          $(function () {
            var children = $('.c-nav > li > a').filter(function () { return $(this).nextAll().length > 0 ;});
            $('<span class="drop-menu"><i class="fas fa-chevron-down"></i></span>').insertAfter(children);
            $('.c-nav .drop-menu').click(function (e) {
              $(this).next().slideToggle(300);
              return false;
            });
          });

        }

          if (!Cookies.get('july_specials')) {
            setTimeout(function () {
              $.fancybox.open({
                src: '.popup-overlay',
                type: 'inline'
              });

            Cookies.set('july_specials', 'true', { expires: 1 });
          }, 1000);
        }
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
